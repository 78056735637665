.top-section {
  background: url("https://playace.co/assets/images/top-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.list-property-top-section {
  background: url("https://playace.co/assets/images/Add-Property12.png");
  background-position: center;
  background-size: 100% 80%;
  background-repeat: no-repeat;
}

:global(.underline) {
  border-bottom: 1px solid #d3cedf;
}

:global(p) {
  line-height: 2;
}

.list-property-bottom-section {
  background: url("https://playace.co/assets/images/strip4.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.background-grey {
  background: #dbdbdb;
}

.fade {
  animation: 0.4s ease-out fadeIn 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

:global(.form-control) {
  &:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
}
