:global(.form-check-label){
    margin-left: 1.5rem;
}

.radio-button {
    display: block;
}

input:focus[type="radio"] {
    border: none !important;
    outline: none !important;
}

.radio-button:focus {
    outline: none;
}