:global(input){
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin: 0 0 0.8rem 0;
}

:global(textarea){
    display: block;
    width: 100%;
    margin: 0 0 0.8rem 0;
    padding: 0.5rem;
}