.container{
    display: flex;
}

.as-a-host{
    justify-self: flex-start;
    width: 50%;
    border-right: 2px solid #979797;
    padding: 1rem 2rem;
}

.as-a-guest{
    justify-self: flex-end;
    width: 50%;
    padding: 1rem 2rem;
}

.card-with-img{
    background: white;
    display: flex;
    border-radius: 15px;
    border-right: 1rem solid #4BB4B2;
    margin: 1.5rem 0 ;
    height: 140px;

    &__img{
        width: 25%;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            // max-width: 250%;
            height: 100%;
        }
    }

    &__content{
        border: none;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
}

.card-without-img{
    background: white;
    display: flex;
    border-radius: 15px;

    &__content{
        width: 100%;
    }
}

.semitransparent{
    opacity: 0.66;
}

@media screen and (max-width: 576px){
    .as-a-host{
        width: 100%;
        border: none;
    }

    .as-a-guest{
        width: 100%;
    }

    .card-with-img{
        height: 180px;
    }
}