.selection {
  padding: 2rem;
  border: none;
}

:global(.active-selection) {
  border: 1px solid #ff1844;
}

.inputcheck {
  width: auto;
  margin-right: 1rem;
}

:global(input:focus) {
  border: none;
  outline: 1px solid black;
}

:global(textarea:focus) {
  border: none;
  outline: 1px solid black;
}

.previous {
  width: 25% !important;
  margin: 3rem auto 0 auto;
  @media screen and (max-width: 420px) {
    //margin: 3rem 0rem 0 0 !important;
    width: 40% !important;
  }
}

.next {
  width: 25% !important;
  margin: 3rem auto 0 auto;
  @media screen and (max-width: 420px) {
    width: 40% !important;
  }
}
